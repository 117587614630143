export enum ConsolePageIds {
  HOME = "home",
  DEMO = "demo",
}

export interface RouteConfig {
  href: string;
  pageName: string;
}

export const ConsoleRouteConfig: Record<ConsolePageIds, RouteConfig> = {
  [ConsolePageIds.HOME]: { href: "/", pageName: "Home" },
  [ConsolePageIds.DEMO]: { href: "/demo", pageName: "Demo" },
};
