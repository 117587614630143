export interface ReportMetadata {
  reportName: string;
  reportDescription: string;
  sourceFileS3Paths: string[];
}

export enum SourceType {
  TERM_SHEET = "TERM_SHEET",
  RENT_ROLL = "RENT_ROLL",
  OPERATING_STATEMENT = "OPERATING_STATEMENT",
  APPRAISAL = "APPRAISAL",
  PERSONAL_FINANCIAL_STATEMENT = "PERSONAL_FINANCIAL_STATEMENT",
  SPONSOR_BACKGROUND = "SPONSOR_BACKGROUND",
  BORROWER_BACKGROUND = "BORROWER_BACKGROUND",
}

export enum Namespace {
  TERM_SHEET = "termSheet",
  RENT_ROLL = "rentRoll",
  OPERATING_STATEMENT = "operatingStatement",
  APPRAISAL = "appraisal",
  PERSONAL_FINANCIAL_STATEMENT = "personalFinancialStatement",
  SPONSOR_BACKGROUND = "sponsorBackground",
  BORROWER_BACKGROUND = "borrowerBackground",
}

export enum MemoComponentSection {
  BORROWER = "BORROWER",
  LOAN_TERMS = "LOAN_TERMS",
  SPONSOR_BACKGROUND = "SPONSOR_BACKGROUND",
  RENT_ROLL_ANALYSIS = "RENT_ROLL_ANALYSIS",
  HISTORICAL_FINANCIALS = "HISTORICAL_FINANCIALS",
  PRO_FORMA_ANALYSIS = "PRO_FORMA_ANALYSIS",
  COLLATERAL_DESCRIPTION_AND_VALUATION = "COLLATERAL_DESCRIPTION_AND_VALUATION",
  GUARANTOR_BALANCE_SHEET_ANALYSIS = "GUARANTOR:BALANCE_SHEET_ANALYSIS",
  GUARANTOR_INCOME_STATEMENT_ANALYSIS = "GUARANTOR:INCOME_STATEMENT_ANALYSIS",
}

export enum MemoOverallSection {
  SUMMARY_AND_RECOMMENDATION = "SUMMARY_AND_RECOMMENDATION",
  STRENGTHS_AND_WEAKNESSES = "STRENGTHS_AND_WEAKNESSES",
  PROJECT_DESCRIPTION = "PROJECT_DESCRIPTION",
}

export enum CsvDataType {
  PRO_FORMA = "PRO_FORMA",
  RENT_ROLL = "RENT_ROLL",
  HISTORICAL_FINANCIALS = "HISTORICAL_FINANCIALS",
  GUARANTOR_BALANCE_SHEET = "GUARANTOR_BALANCE_SHEET",
  GUARANTOR_CASH_FLOW = "GUARANTOR_CASH_FLOW",
}

export enum AnswerType {
  STRING = "string",
  NUMBER = "number",
  DATE = "date",
  BOOLEAN = "boolean",
}

export interface BaseReport {
  reportId: string;
  userId: string;
  reportMetadata: ReportMetadata;
  data: Record<string, Record<string, Answer[]>>;
}

export interface Answer {
  value: string;
  type: AnswerType;
  unit?: string;
  citation?: string;
}

export interface Question {
  prompt: string;
  options?: string[];
  multiline?: boolean;
  type: "string" | "number" | "date" | "boolean";
  condition?: Condition;
}

export interface Condition {
  name: string;
  value: string;
  effect: "match" | "notMatch";
}

export interface SectionCreation {
  title: string;
  references?: Reference[];
  data?: CsvData[];
}

export interface CsvData {
  type: CsvDataType;
}

export interface CsvS3Path {
  s3Path: string;
  type: CsvDataType;
}

export interface Reference {
  namespace: string;
  attribute: string;
}

export interface Section {
  title: string;
  text: string;
  children: Section[];
}

export interface ComponentSectionWorkflowStep {
  type: MemoComponentSection;
  label: string;
  data?: CsvData[];
}

export interface OverallSectionWorkflowStep {
  type: MemoOverallSection;
  label: string;
}

export type GenerateMemoWorkflowStepType = MemoComponentSection | MemoOverallSection | "UPLOAD_DATA" | "FINALIZE_REPORT";
