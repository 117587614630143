import LoadingBar from "@cloudscape-design/chat-components/loading-bar";
import Box from "@cloudscape-design/components/box";
import Container from "@cloudscape-design/components/container";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import Header from "@cloudscape-design/components/header";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { FunctionComponent, useEffect, useState } from "react";

import { Answer, BaseReport } from "../../common/types";
import { AnswerView } from "./AnswerView";
import SegmentedControl from "@cloudscape-design/components/segmented-control";
import FormField from "@cloudscape-design/components/form-field";
import Button from "@cloudscape-design/components/button";
import Select from "@cloudscape-design/components/select";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { saveBaseReport } from "../../api/savedReports";

export interface PreviewItem {
  label: string;
  value: string | number;
  multiline?: boolean;
}

const convertCamelCaseToTitleCase = (camelCase: string) => {
  const camelCasePattern = /([a-z])([A-Z])/g;
  const titleCase = camelCase.replace(camelCasePattern, "$1 $2");
  return titleCase.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const PreviewBoard: FunctionComponent<{
  baseReport: BaseReport | null;
  onSave: (baseReport: BaseReport) => void;
}> = ({ baseReport, onSave }) => {
  const [mode, setMode] = useState("view");
  const [selectedNamespace, setSelectedNamespace] = useState<OptionDefinition | null>(null);
  const [currentBaseReport, setCurrentBaseReport] = useState<BaseReport | null>(null);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    setCurrentBaseReport(baseReport);
    setSelectedNamespace(null);
  }, [baseReport]);

  const createNamespaceOptionsFromBaseReport = (baseReport: BaseReport) => {
    // list of all top level keys in the data object
    const namespaces = Object.keys(baseReport.data || {});
    return namespaces.map((namespace) => ({
      label: convertCamelCaseToTitleCase(namespace),
      value: namespace,
    }));
  };
  if (!currentBaseReport) return null;

  return (
    <Container
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween size="xs" direction="horizontal">
              <SegmentedControl
                selectedId={mode}
                onChange={({ detail }) => setMode(detail.selectedId)}
                label="View mode"
                options={[
                  { text: "View", id: "view" },
                  { text: "Edit", id: "edit" },
                ]}
              />
              <Button
                variant="normal"
                disabled={mode === "view"}
                onClick={() => {
                  setCurrentBaseReport(baseReport);
                  setMode("view");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="normal"
                disabled={mode === "view"}
                loading={saveLoading}
                onClick={() => {
                  setSaveLoading(true);
                  saveBaseReport(currentBaseReport)
                    .then((_) => {
                      onSave(currentBaseReport);
                    })
                    .catch((error) => {
                      console.error("Failed to save report", error);
                    })
                    .finally(() => {
                      setMode("view");
                      setSaveLoading(false);
                    });
                }}
              >
                Save
              </Button>
            </SpaceBetween>
          }
        >
          Preview
        </Header>
      }
    >
      <SpaceBetween size={"m"}>
        <KeyValuePairs
          columns={4}
          items={[
            {
              label: "Report ID",
              value: currentBaseReport.reportId,
            },
            {
              label: "User ID",
              value: currentBaseReport.userId,
            },
            {
              label: "Report name",
              value: currentBaseReport.reportMetadata.reportName,
            },
            {
              label: "Report description",
              value: currentBaseReport.reportMetadata.reportDescription,
            },
          ]}
        />
        <FormField label="Document Namespace">
          <Select
            options={createNamespaceOptionsFromBaseReport(currentBaseReport)}
            selectedOption={selectedNamespace}
            onChange={({ detail }) => setSelectedNamespace(detail.selectedOption)}
          />
        </FormField>
        {Object.entries(currentBaseReport.data[selectedNamespace?.value || ""] || {}).flatMap(([collectionName, items]) => (
          <ExpandableSection headerText={`${convertCamelCaseToTitleCase(collectionName)} (${items.length})`} key={`${selectedNamespace?.value}-${collectionName}`}>
            <SpaceBetween size={"s"}>
              {items.map((item, index) => (
                <AnswerView
                  inEdit={mode === "edit"}
                  index={`${index + 1}`}
                  key={`${selectedNamespace?.value}-${collectionName}-${index}`}
                  answer={item}
                  onChange={(answer) => {
                    const modifiedBaseReport = JSON.parse(JSON.stringify(currentBaseReport));
                    modifiedBaseReport.data[selectedNamespace?.value || ""][collectionName][index] = answer;
                    setCurrentBaseReport(modifiedBaseReport);
                  }}
                />
              ))}
            </SpaceBetween>
          </ExpandableSection>
        ))}
      </SpaceBetween>
    </Container>
  );
};

const PreviewLoading = () => {
  return (
    <Container header={<Header variant="h2">Preview</Header>}>
      <div aria-live="polite">
        <LoadingBar variant="gen-ai" />
      </div>
    </Container>
  );
};
