import Wizard from "@cloudscape-design/components/wizard";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import { FunctionComponent, useEffect, useState } from "react";
import Link from "@cloudscape-design/components/link";
import Container from "@cloudscape-design/components/container";
import FormField from "@cloudscape-design/components/form-field";
import FileUpload from "@cloudscape-design/components/file-upload";
import { BaseReport, Namespace, ReportMetadata, SourceType } from "../../common/types";
import { createReport, saveBaseReport } from "../../api/savedReports";
import { extractFeatures, uploadFile } from "../../api/featureExtraction";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";
import { BasicInformation } from "./BasicInformation";
import { GenerationSteps, GenerationStatusView } from "./GenerationStatusView";
import { StatusIndicatorProps } from "@cloudscape-design/components/status-indicator";

export const CollectionWizard: FunctionComponent<{
  onSubmit: () => void;
  onSuccess: (baseReports: BaseReport) => void;
  onCancel: () => void;
}> = ({ onSubmit, onSuccess, onCancel }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [termSheet, setTermSheet] = useState<File[]>([]);
  const [operatingStatement, setoperatingStatement] = useState<File[]>([]);
  const [rentRoll, setRentRoll] = useState<File[]>([]);
  const [appraisal, setAppraisal] = useState<File[]>([]);
  const [personalFinancialStatement, setPersonalFinancialStatement] = useState<File[]>([]);
  const [sponsorBackground, setSponsorBackground] = useState<File[]>([]);
  const [borrowerBackground, setBorrowerBackground] = useState<File[]>([]);
  const [fileErrors, setFileErrors] = useState<string[]>([]);

  const defaultState: Record<GenerationSteps, StatusIndicatorProps.Type> = {
    [GenerationSteps.INITIALIZING]: "pending",
    [GenerationSteps.EXTRACT_FEATURES_TERM_SHEET]: "pending",
    [GenerationSteps.EXTRACT_FEATURES_OPERATING_STATEMENT]: "pending",
    [GenerationSteps.EXTRACT_FEATURES_APPRAISAL]: "pending",
    [GenerationSteps.EXTRACT_FEATURES_RENT_ROLL]: "pending",
    [GenerationSteps.EXTRACT_FEATURES_PFS]: "pending",
    [GenerationSteps.EXTRACT_FEATURES_SPONSOR_BACKGROUND]: "pending",
    [GenerationSteps.EXTRACT_FEATURES_BORROWER_BACKGROUND]: "pending",
  };

  const [extractionState, setExtractionState] = useState<Record<GenerationSteps, StatusIndicatorProps.Type>>(defaultState);

  const [extractionStarted, setExtractionStarted] = useState(false);

  const userId = "demo-user"; // TODO: Get user id from auth context
  const [reportMetadata, setReportMetadata] = useState<ReportMetadata>({
    reportName: "",
    reportDescription: "",
    sourceFileS3Paths: ["-"],
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const errors = termSheet.map((file) => {
      if (file.type !== "application/pdf") {
        return "Please upload files in PDF format";
      }
      return "";
    });
    setFileErrors(errors);
  }, [termSheet]);

  const uploadFiles = async (files: File[], sourceType: SourceType) => {
    try {
      const s3FilePaths = await Promise.all(files.map((file) => uploadFile(file, { source_type: sourceType, user_id: userId })));
      return s3FilePaths;
    } catch (error) {
      console.error("Encountered error while uploading files", error);
      return undefined;
    }
  };

  const executeRun = async (
    results: BaseReport,
    runState: Record<GenerationSteps, StatusIndicatorProps.Type>,
    generationStep: GenerationSteps,
    sourceType: SourceType,
    files: File[],
    resultsKey: Namespace
  ) => {
    try {
      runState[generationStep] = "success";
      setExtractionState({ ...runState });
      runState[generationStep] = "loading";
      setExtractionState({ ...runState });
      const s3FilePaths = await uploadFiles(files, sourceType);
      if (!s3FilePaths) {
        runState[generationStep] = "error";
        setExtractionState({ ...runState });
        return;
      }
      results.reportMetadata.sourceFileS3Paths.push(...s3FilePaths);
      results.data = {
        ...results.data,
        [resultsKey]: await extractFeatures(s3FilePaths, sourceType),
      };
      await saveBaseReport(results);
      runState[generationStep] = "success";
      setExtractionState({ ...runState });
    } catch (error) {
      runState[generationStep] = "error";
      setExtractionState({ ...runState });
      console.error("Encountered error while executing run", error);
    }
  };

  if (extractionStarted) {
    return (
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <Button
                onClick={() => {
                  setExtractionStarted(false);
                  setExtractionState(defaultState);
                }}
              >
                Close
              </Button>
            }
          >
            Generation Status
          </Header>
        }
      >
        <GenerationStatusView records={extractionState} />
      </Container>
    );
  }

  const handleSubmit = async () => {
    setLoading(true);
    setExtractionStarted(true);
    try {
      const runState = { ...defaultState };
      runState[GenerationSteps.INITIALIZING] = "loading";
      setExtractionState({ ...runState });
      const reportId = await createReport(userId, reportMetadata);
      console.log("Report Created with ID: ", reportId);
      if (!reportId) {
        runState[GenerationSteps.INITIALIZING] = "error";
        setExtractionState({ ...runState });
        return;
      }
      const results = {
        reportId,
        userId,
        reportMetadata: {
          ...reportMetadata,
          sourceFileS3Paths: [] as string[],
        },
        data: {},
      };
      runState[GenerationSteps.INITIALIZING] = "success";
      setExtractionState({ ...runState });
      await executeRun(results, runState, GenerationSteps.EXTRACT_FEATURES_TERM_SHEET, SourceType.TERM_SHEET, termSheet, Namespace.TERM_SHEET);
      await executeRun(results, runState, GenerationSteps.EXTRACT_FEATURES_OPERATING_STATEMENT, SourceType.OPERATING_STATEMENT, operatingStatement, Namespace.OPERATING_STATEMENT);
      await executeRun(results, runState, GenerationSteps.EXTRACT_FEATURES_APPRAISAL, SourceType.APPRAISAL, appraisal, Namespace.APPRAISAL);
      await executeRun(results, runState, GenerationSteps.EXTRACT_FEATURES_RENT_ROLL, SourceType.RENT_ROLL, rentRoll, Namespace.RENT_ROLL);
      await executeRun(
        results,
        runState,
        GenerationSteps.EXTRACT_FEATURES_PFS,
        SourceType.PERSONAL_FINANCIAL_STATEMENT,
        personalFinancialStatement,
        Namespace.PERSONAL_FINANCIAL_STATEMENT
      );
      await executeRun(results, runState, GenerationSteps.EXTRACT_FEATURES_SPONSOR_BACKGROUND, SourceType.SPONSOR_BACKGROUND, sponsorBackground, Namespace.SPONSOR_BACKGROUND);
      await executeRun(results, runState, GenerationSteps.EXTRACT_FEATURES_BORROWER_BACKGROUND, SourceType.BORROWER_BACKGROUND, borrowerBackground, Namespace.BORROWER_BACKGROUND);
      console.log(results);
      if (!results) throw new Error("Failed to extract features");
      await saveBaseReport(results);
      onSuccess(results);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const i18nStrings = {
    uploadButtonText: (e: any) => (e ? "Choose files" : "Choose file"),
    dropzoneText: (e: any) => (e ? "Drop files to upload" : "Drop file to upload"),
    removeFileAriaLabel: (e: number) => `Remove file ${e + 1}`,
    limitShowFewer: "Show fewer files",
    limitShowMore: "Show more files",
    errorIconAriaLabel: "Error",
  };

  const CHOOSE_FILE = "Choose file from your device";
  const CONSTRAINT_TEXT = "Please upload files in PDF format";

  return (
    <Container>
      <Wizard
        onSubmit={() => {
          onSubmit();
          handleSubmit();
        }}
        onCancel={() => {
          setActiveStepIndex(0);
          onCancel();
        }}
        isLoadingNextStep={loading}
        i18nStrings={{
          stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Generate",
          optional: "optional",
        }}
        onNavigate={({ detail }) => setActiveStepIndex(detail.requestedStepIndex)}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        steps={[
          {
            title: "Basic Information",
            info: <Link variant="info">Info</Link>,
            description: "Provide some descriptions for the case",
            content: <BasicInformation reportMetadata={reportMetadata} onChange={setReportMetadata} />,
          },
          {
            title: "Upload Source Documents",
            info: <Link variant="info">Info</Link>,
            description: "First we need to collect some information",
            content: (
              <Container>
                <SpaceBetween direction="vertical" size="m">
                  <FormField label="Term Sheet" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setTermSheet(detail.value)}
                      value={termSheet}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Operating Statement" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setoperatingStatement(detail.value)}
                      value={operatingStatement}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Rent Roll" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setRentRoll(detail.value)}
                      value={rentRoll}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Appraisal" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setAppraisal(detail.value)}
                      value={appraisal}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Personal Financial Statement" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setPersonalFinancialStatement(detail.value)}
                      value={personalFinancialStatement}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Sponsor Background" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setSponsorBackground(detail.value)}
                      value={sponsorBackground}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                  <FormField label="Borrower Background" description={CHOOSE_FILE} info={<Link variant="info">Info</Link>}>
                    <FileUpload
                      onChange={({ detail }) => setBorrowerBackground(detail.value)}
                      value={borrowerBackground}
                      fileErrors={fileErrors}
                      i18nStrings={i18nStrings}
                      showFileLastModified
                      showFileSize
                      showFileThumbnail
                      constraintText={CONSTRAINT_TEXT}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Review and Generate",
            content: (
              <SpaceBetween size="xs">
                <Header variant="h3" actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}>
                  Source Documents
                </Header>
                <KeyValuePairs
                  columns={3}
                  items={[
                    {
                      label: "User ID",
                      value: userId,
                    },
                    {
                      label: "Report Name",
                      value: reportMetadata.reportName,
                    },
                    {
                      label: "Report Description",
                      value: reportMetadata.reportDescription,
                    },
                    {
                      label: "Term Sheet",
                      value: termSheet.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Operating Statement",
                      value: operatingStatement.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Rent Roll",
                      value: rentRoll.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Appraisal",
                      value: appraisal.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Personal Financial Statement",
                      value: personalFinancialStatement.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Sponsor Background",
                      value: sponsorBackground.map((file) => file.name).join(", "),
                    },
                    {
                      label: "Borrower Background",
                      value: borrowerBackground.map((file) => file.name).join(", "),
                    },
                  ]}
                />
              </SpaceBetween>
            ),
          },
        ]}
      />
    </Container>
  );
};
